@use "../../../sass/colors" as *;
@use "../../../sass/fonts" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.circleLarge {
  aspect-ratio: 1;
  box-shadow: inset 0 0 0 5px $gray20;
  border-radius: 50%;
  &.coloredCircle {
    box-shadow: inset 0 0 0 5px $blue20;
  }
}

.circleSmall {
  @extend .circleLarge;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dottedLine {
  position: absolute;
  height: 2px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 5px,
    $gray20 5px,
    $gray20 10px
  );
  left: 50%;
  width: 210px;
  transform: translateY(-50%);
  &.coloredLine {
    background: repeating-linear-gradient(
      to right,
      transparent,
      transparent 5px,
      $blue20 5px,
      $blue20 10px
    );
  }
}

.dottedLineLarge {
  @extend .dottedLine;
  top: 20px;
}

.dottedLineMiddle {
  @extend .dottedLine;
}

.dottedLineSmall {
  @extend .dottedLine;
  top: calc(100% - 20px);
}

.value {
  @extend .bodyBoldFont;
  position: absolute;
  left: 125%;
  transform: translateY(-50%);
}

.valueLarge {
  @extend .value;
  top: 20px;
}

.valueSmall {
  @extend .value;
  top: calc(100% - 20px);
}
